

import {GatsbyImage} from "gatsby-plugin-image"
import MeinSEO from '../components/SEO'
import BasicUsage from '../components/BasicUsage'
//import {motion} from 'framer-motion'
import Link from '../components/gatsbylink'
import MyContext from '../context/MyContext'



import {Box,
 
  useDisclosure,
  Center,
  Text,
  Heading,
  SimpleGrid,
  Flex,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ListItem,
  List,
  
  } from '@chakra-ui/react'
import { graphql, navigate} from "gatsby"
import React, { useEffect } from 'react'


function Termine() {
  return (
    <>
    <Link to="/pdf/Programm-2022.pdf">
    <Text my="0" py="0"  _hover={{color:"blue"}} >
      &rarr; SPIELPLAN
      </Text> 
      </Link>
    </>
  )
}

function MSponsor() {
  const { isOpen, onClose } = useDisclosure({defaultIsOpen: true })
  const {eng,setEng} =React.useContext(MyContext)
 

  return (
  
  
    <Box mt={1}>
      {/* <Button  p="1" onClick={onOpen}>Danksagung an die Sponsoren des Theaterfestials</Button> */}

      <Modal  motionPreset='slideInBottom' closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent >
          
          <ModalCloseButton onClick={()=>setEng(!eng)} />
          <ModalBody mt={6}>
            <Text fontSize="md">Nach drei erfolgreichen und bewegenden Theaterwochen möchten wir als Team des Theaterfestivals „An den Ufern der Poesie“ (siehe Foto) den zahlreichen Unterstützern danken. Neben den vielen ehrenamtlichen Helferinnen und Helfern gilt unser besonderer Dank den finanziellen Förderern, ohne die das Festival nicht möglich wäre. Ganz herzlichen Dank an:</Text>
           <List size="sm">
            <ListItem pb="0">
              <Link href="https://www.facebook.com/kultursommer.rlp">
              <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Kultursommer RLP </Text>
              </Link> 
            </ListItem>
            <ListItem pb="0">
              <Link href="https://www.facebook.com/sebamed.Deutschland/">
                <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Sebapharma GmbH & Co. KG </Text></Link>
            </ListItem>
            <ListItem pb="0">
              <Link href="https://www.lotto-rlp.de/">
                <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; LOTTO RLP GmbH </Text></Link>
            </ListItem>
            <ListItem py="0">
              <Link href="https://www.facebook.com/meinenaspa/">
              <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Naspa Stiftung </Text>
              </Link>
            </ListItem>
            <ListItem py="0">
              <Link href="https://www.leifheit-stiftung-nassau.de/">
                <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; G. und I. Leifheit Stiftung </Text>
                </Link>
            </ListItem>
            <ListItem py="0">
              <Link href="https://de-de.facebook.com/pg/SpkRheinNahe/posts/ ">
              <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Kreissparkasse Rhein-Nahe</Text>
               </Link>
            </ListItem>
            <ListItem py="0">
              <Link href="https://www.facebook.com/Westenergie/">
                <Text  fontSize="md" p="0" m="0" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Westenergie AG </Text>
              </Link>
            </ListItem>
            <ListItem py="0">
              <Link href="https://hovacal.de/en">
              <Text p="0" m="0" fontSize="md"_hover={{color: 'green  ',cursor:'pointer'}}> &rarr; IAS GmbH  (Inspire Analytical Systems) </Text>
              </Link>
            </ListItem>
            <ListItem py="0">
              <Link href="https://www.facebook.com/meineEVM">
                <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; EVM Energieversorgung Mittelrhein AG </Text>
                </Link>
            </ListItem>
            <ListItem py="0">
              <Link href="https://www.facebook.com/SuewagEnergieAGp">
              <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Süwag Energie AG </Text>
             </Link>
             </ListItem>
            <ListItem py="0"><Link href="https://www.facebook.com/kskrheinhunsrueck/">
              <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Kreissparkasse Rhein-Hunsrück </Text>
            </Link>
            </ListItem>
            <ListItem py="0">
              <Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Volksbank Rhein-Lahn-Limburg eG </Text>
              <Link href="https://www.voba-rll.de/startseite.htmlp"></Link>
            </ListItem>
            <ListItem py="0">
              <Link href="https://www.facebook.com/VobaRNH"><Text p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Volksbank Rhein-Nahe-Hunsrück eG</Text>
            </Link>
            </ListItem>
            <ListItem py="0"><Link href="https://www.welterbe-mittelrheintal.de/zweckverband-wom">
              <Text  p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}> &rarr; Zweckverband Welterbe Oberes Mittelrheintal</Text>
              </Link> 
            </ListItem>
            <ListItem py="0">
              <Link href="https://boppard-st-goar.rotary.de">
              <Text p="0" m="0" fontSize="md"  _hover={{color: 'green  ',cursor:'pointer'}}>&rarr; Rotary Club</Text></Link> 
            </ListItem>
            <ListItem py="0">
              <Link href=" http://www.lions-rheingoldstrasse.de/index.html"> 
              <Text  p="0" m="0" fontSize="md" _hover={{color: 'green  ',cursor:'pointer'}}>&rarr; Lions Club</Text>
              </Link>
           </ListItem>
           </List>
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </Box>
  
  )
  
}
function Sponsoren() {
  
  return (
    <>
    <Link to="/sponsoren">
    <Text my="0"   _hover={{color:"blue"}} role="pushbutton" cursor="pointer"    
        variant="unstyled"  outline="none" color="text" border="none">
           &rarr; SPONSOREN
    </Text>
    </Link>
    </>
  )
}




const IndexPage = ({data}) => {


const {eng} = React.useContext(MyContext)

useEffect(()=>{
 
},[eng])
 

 
  //const [geladen,setGeladen]=useState(false)
  //const [warnung,setWarnung] = useState(false)
 //const [ptest,setPtest]=useState("jj")
  // useEffect(()=>{
  //   setGeladen(true)
  // },[])
  // const Info=({titel,text})=>{
  //   return(
  //     <Center>
  //     <motion.div 
  //      initial={{opacity:0,position:"absolute",width:"95%",maxWidth:600,display:"flex",top:100, flexWrap:"wrap",flexDirection:'column',margin:'auto', justifyContent:"flex-end", zIndex:200}}
  //      animate= {{opacity:1,y:10,x:10}}
       
  //     >
  //     <Box p="3" bg="blue.400">
  //       <Heading color="white">{titel}</Heading>
  //       <Text color="white">{text}</Text>
  //       <Button onClick={()=>setWarnung(!warnung)}>Close</Button>
  //     </Box>
  //     </motion.div>
  //     </Center>
  //   )
  // }
  function Kasten({myimg,title,text, linktext,linkhinweis}){
   
    return(
      
      <Box mt="3" mb="0" width="100%" cursor="pointer" onClick={()=>navigate(linktext)}>
        <MeinSEO title="Startseite Theaterfestial 2022"
        description="Webseite zum Theaterfestival 'An den Ufern der Poesie'"
        keywords="Theaterfestival, Bacharach, Oberwesel, Oberes Mittelrheintal"
        />
      <Box>
       
            <GatsbyImage image={myimg} alt="" />
          
        
            <Heading lineHeight="1" variant="title" >
              {title}
              </Heading>
          </Box>
              <Text p="0" mt="-1" lineHeight="1"   maxWidth="500px" mb="4">
            {text}
           </Text>
           
        </Box>
    )
    
  }
  

 
if(data){
  return (
    <>
   
       <div style={{margin:'auto',padding:"20px 10px",maxWidth:"1100"}}>
      
          <Flex flexDirection={['column','column','row']} p="0" m="0" pt="2"   width="100%"  alignItems="space-between">
           <Box m="0" py="1"   textAlign="left" flex="1" mr="2"> <BasicUsage /> </Box>
           <Box m="0" py="1"   textAlign="left" flex="1" mr="2"> <Termine /> </Box>
           <Box m="0" py="1"   textAlign="left" flex="1" mr="2"> <Sponsoren /> </Box>
           
           <Box py="0" my="0"><Link to="/tickets"><Text _hover={{color:'blue'}}  m="0">&rarr; TICKETVERKAUF </Text></Link></Box>
         </Flex> 
       {/* <Center>  */}
      
       {!eng&&<MSponsor />
       }
       
        <SimpleGrid py="3" columns={[1,1,2,3,null,'1fr']} spacing={["0","0","6"]}>
       
        <Kasten 
          myimg={data.bild10.childImageSharp.gatsbyImageData}
          title="Prolog"
          text="Wir festspielen"
          linktext="/prolog"
          linkhinweis="mehr ..."
          /> 
        <Kasten 
          myimg={data.bild1.childImageSharp.gatsbyImageData}
          title="Epidemische Krisen. Nichts Neues!"
          text=" Heine berichtet von der Cholera 1832 in Paris."
          linktext="/cholera"
          linkhinweis="Heine und die Cholera"
          />
          <Kasten 
          myimg={data.bild0.childImageSharp.gatsbyImageData}
          title="Der Rabbi von Bacharach"
          text=" Stationen eines Traumas"
          linktext="/rabbi"
          linkhinweis="Heine und die Cholera"
          />
            <Kasten 
          myimg={data.bild9.childImageSharp.gatsbyImageData}
          title="Die Judenbuche"
          text="Ein Kriminalfall aus der Zeit der Romantik"
          linktext="/judenbuche"
          linkhinweis="Droste-Hülshof"
          /> 
       <Kasten 
          myimg={data.bild2.childImageSharp.gatsbyImageData}
          title="Podium Szene + Dialog"
          text="Shylock erklärt Shylock"
          linktext="/shylock"
          linkhinweis="Dialog und Szene. Shylock erklärt Shylock"
          /> 
      <Kasten 
          myimg={data.bild5.childImageSharp.gatsbyImageData}
          title="In Auschwitz gab es keine Vögel."
          text="Konzert-Lesung"
          linktext="/auschwitz"
          linkhinweis="Auschwitz ..."
          />  
    
     <Kasten 
          myimg={data.bild7.childImageSharp.gatsbyImageData}
          title="Podium Szene + Dialog"
          text="Sankt Werner von Oberwesel. 700 Jahre antisemitische Fake News"
          linktext="/fake"
          linkhinweis="Dialog ..."
          /> 
     
     <Kasten 
          myimg={data.bild11.childImageSharp.gatsbyImageData}
          title="Manifest"
          text="An den Ufern der Poesie."
          linktext="/theatermanifest"
          linkhinweis="Manifest ..."
          /> 
         <Kasten 
          myimg={data.bild12.childImageSharp.gatsbyImageData}
          title="Verluste"
          text="Ausstellungsparcours in Bacharach und Oberwesel"
          linktext="https://juedisches-leben-am-mittelrhein.de"
          linkhinweis="Jüdisches Leben im Mittelrheintal"
          /> 

             
    </SimpleGrid>
     {/* </Center> */}
    
     </div>
    <Center> gefördert durch:</Center>
     <Flex margin="auto" maxWidth={["300","400","600"]} justifyContent="center" alignItems="center"  >
       <Box><GatsbyImage  image={data.logo1.childImageSharp.gatsbyImageData} alt=""/></Box>
       <Box><GatsbyImage image={data.logo2.childImageSharp.gatsbyImageData} alt=""/></Box>
       <Box><GatsbyImage image={data.logo3.childImageSharp.gatsbyImageData} alt=""/></Box>
       <Box><GatsbyImage image={data.logo4.childImageSharp.gatsbyImageData} alt=""/></Box>
       <Box><GatsbyImage image={data.logo5.childImageSharp.gatsbyImageData} alt=""/></Box>
     

     </Flex>

   </>
  )}else{
    return(<Flex alignItems="center">
  
     <Box mb="30px">
     <Heading size="2xl"><Spinner size="lg"/></Heading>
     </Box>
     
  
    </Flex>)
    
  }
}

export const query = graphql`
  query {
    bild1:file(relativePath: { eq: "web/teaser-1.jpg" }) {
      childImageSharp {
       gatsbyImageData(
         
         placeholder: BLURRED
         formats: [AUTO,WEBP,AVIF]
       )
      }
    }
    bild0:file(relativePath: { eq: "theater/rabbi.jpeg" }) {
      childImageSharp {
       gatsbyImageData(
         
         placeholder: BLURRED
         formats: [AUTO,WEBP,AVIF]
       )
      }
    }
    bild2:file(relativePath: { eq: "web/teaser-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
         
          placeholder: BLURRED
          formats: [AUTO,WEBP,AVIF]
        )
       
      }
    }
    bild3:file(relativePath: { eq: "web/teaser-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
         
          placeholder: BLURRED
          formats: [AUTO,WEBP,AVIF]
        )
       }
      
    }
    
      bild5:file(relativePath: { eq: "web/teaser-5.jpg" }) {
        childImageSharp {
          gatsbyImageData(
         
            placeholder: BLURRED
            formats: [AUTO,WEBP,AVIF]
          )
         }
    }
    bild6:file(relativePath: { eq: "web/teaser-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(
         
          placeholder: BLURRED
          formats: [AUTO,WEBP,AVIF]
        )
       }
  }
  bild7:file(relativePath: { eq: "web/teaser-7.jpg" }) {
    childImageSharp {
      gatsbyImageData(
         
        placeholder: BLURRED
        formats: [AUTO,WEBP,AVIF]
      )
     }

}
   bild8:file(relativePath: { eq: "web/teaser-8.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO,WEBP,AVIF]
        )
       }
      
  }

  bild9:file(relativePath: { eq: "web/teaser-x.jpg" }) {
    childImageSharp {
      gatsbyImageData(
           
        placeholder: BLURRED
        formats: [AUTO,WEBP,AVIF]
      )
     }
}
bild10:file(relativePath: { eq: "web/prolog.jpg" }) {
  childImageSharp {
    gatsbyImageData(
         
      placeholder: BLURRED
      formats: [AUTO,WEBP,AVIF]
    )
   }
}
bild11:file(relativePath: { eq: "web/manifest.jpg" }) {
  childImageSharp {
    gatsbyImageData(
         
      placeholder: BLURRED
      formats: [AUTO,WEBP,AVIF]
    )
   }
}
bild12:file(relativePath: { eq: "web/verluste.jpg" }) {
  childImageSharp {
    gatsbyImageData(
         
      placeholder: BLURRED
      formats: [AUTO,WEBP,AVIF]
    )
   }
}

logo1:file(relativePath: { eq: "logos/welterbe.jpg" }) {
  childImageSharp {
    gatsbyImageData(
         
      placeholder: BLURRED
      formats: [AUTO,WEBP,AVIF]
    )
   }
}
logo2:file(relativePath: { eq: "logos/leben.jpg" }) {
  childImageSharp {
    gatsbyImageData(
         
      placeholder: BLURRED
      formats: [AUTO,WEBP,AVIF]
    )
   }
}
logo3:file(relativePath: { eq: "logos/pfalz.jpg" }) {
  childImageSharp {
    gatsbyImageData(
         
      placeholder: BLURRED
      formats: [AUTO,WEBP,AVIF]
    )
   }
}
logo4:file(relativePath: { eq: "logos/kultursommer.jpg" }) {
  childImageSharp {
    gatsbyImageData(
         
      placeholder: BLURRED
      formats: [AUTO,WEBP,AVIF]
    )
   }
}
logo5:file(relativePath: { eq: "logos/praml.jpg" }) {
  childImageSharp {
    gatsbyImageData(
         
      placeholder: BLURRED
      formats: [AUTO,WEBP,AVIF]
    )
   }
}
}
`
export default IndexPage
