import React from 'react'
import {Button,Box,Modal,ModalBody,ModalOverlay,ModalCloseButton,ModalContent,Heading, useDisclosure} from '@chakra-ui/react'
import MeinSEO from './SEO'
import Link from '../components/gatsbylink'
export default function BasicUsage() {
    const { isOpen, onOpen, onClose } = useDisclosure()
  
    return (
      <>
      <Box>
        <MeinSEO title="Neuste Informationen" 
      description="Informationen zum Theaterfestival 2021" />
      
        <Button fontWeight="100" role="pushbutton" cursor="pointer"    
        variant="unstyled" fontSize="lg" outline="none" color="text" border="none" onClick={onOpen}> &rarr; Downloads</Button>
      
        <Modal position="relative" marginTop="360px" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            
            <ModalCloseButton color="gray.800" />
            <ModalBody bg="white">
              <Heading>Download</Heading>
             {/* <Link href="/pdf/juedisches-leben.pdf"> &rarr; Plan Ausstellungsparcours</Link><br/> */}
             <Link href="/pdf/Programm-2022.pdf"> &rarr; Progamm Theaterfestival 2022</Link>
             
            </ModalBody>
    
           
          </ModalContent>
        </Modal>
      </Box>
     
        
      </>
    )
  }